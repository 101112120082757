import * as React from 'react';

import { CardProps } from '@/types/components/display/Card';
import { classNames } from '@/utils/functions/class-names';

const Card = (props: CardProps) => {
    const { className, children } = props;

    return <section className={classNames('card', className)}>{children}</section>;
};

export default Card;
