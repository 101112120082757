import * as React from 'react';

import DateFormat from '@core/components/display/datetime/DateFormat';
import { GiftIcon } from '@heroicons/react/20/solid';
import { ClipboardDocumentIcon, EllipsisHorizontalIcon, ShareIcon } from '@heroicons/react/24/outline';
import { Dimensions } from '@research-api/utils/images';
import Image from 'next/image';
import Link from '@core/components/display/links/Link';
import { useRouter } from 'next/router';
import { SafeStrapiEntity, Flashnote, Article } from '@blockworks/platform/src/api/strapi/models';
import Label from '@core/components/display/labels/Label';
import { useTrack } from '@core/utils/contexts/analytics/AnalyticsProvider';

import ArticleTags from '@/components/pages/articles/ArticleTags';
import PostSkeleton from '@/components/section/Post/PostSkeleton';
import { classNames } from '@/utils/functions/class-names';
import { AnalyticsEvent } from '@/utils/enums/eventsTracking';

export enum PostImageSize {
    tiny,
    small,
    big,
}

type PostProps = {
    article: SafeStrapiEntity<Article | Flashnote>;
    showExcerpt?: boolean;
    imageSize: PostImageSize;
    flexRow?: boolean;
    isLoadingPosts?: boolean;
};

const Post = (props: PostProps) => {
    const track = useTrack();
    const router = useRouter();
    const { article, showExcerpt, imageSize, flexRow, isLoadingPosts } = props;
    const isArticle = 'isFree' in article;
    const linkHref = isArticle ? `/research/${article.slug}` : `/flashnotes/${article.slug}`;

    if (isLoadingPosts) {
        return (
            <div className={classNames('flex', flexRow ? 'flex-col xl:flex-row xl:inline-flex' : 'flex-col')}>
                <PostSkeleton imageSize="small" />
            </div>
        );
    }

    let imageDimensions: Dimensions;
    switch (imageSize) {
        case PostImageSize.tiny:
            imageDimensions = { width: 960 / 5, height: 540 / 5 };
            break;
        case PostImageSize.big:
            imageDimensions = { width: 960, height: 540 };
            break;
        case PostImageSize.small:
        default:
            imageDimensions = { width: 960 / 2, height: 540 / 2 };
            break;
    }

    const renderFlashnoteLabel = () => {
        if (isArticle) return null;

        return (
            <Label href={`/flashnotes`} className="" smaller purple>
                Flashnote
            </Label>
        );
    };

    return (
        <div
            key={`post-${article.slug}`}
            className={classNames('flex', flexRow ? 'flex-col xl:flex-row gap-2 xl:gap-6' : 'flex-col')}
        >
            {article.featuredImage?.url && (
                <div className={classNames('flex-shrink-0 relative')}>
                    <Link
                        href={linkHref}
                        className={classNames(
                            'flex overflow-hidden p-0.5 border border-primary hover:border-black w-full rounded-xl cursor-pointer',
                            flexRow ? 'xl:my-2 2xl xl:self-center' : '',
                        )}
                    >
                        <Image
                            width={imageDimensions.width}
                            height={imageDimensions.height}
                            style={{ borderRadius: '0.65rem' }}
                            className={classNames(
                                'w-full object-cover object-center aspect-16/9',
                                flexRow ? 'xl:w-72' : '',
                                (article as SafeStrapiEntity<Article>).isFree ? 'pulse-purple' : '',
                            )}
                            src={`${article.featuredImage.url}`}
                            alt={article.featuredImage.alternativeText || ''}
                            priority
                            layout="responsive"
                        />
                    </Link>
                </div>
            )}
            <div className="flex-1 p-0 flex flex-col">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="py-3 flex w-full">
                        <div className="w-full flex justify-between">
                            <div className="m-0 text-sm font-medium text-indigo-600 space-x-2 h-6 overflow-hidden max-w-full">
                                <ArticleTags tags={article.tags} className="mb-2" />
                            </div>
                            {renderFlashnoteLabel()}
                        </div>
                        <div className="grow">&nbsp;</div>
                        {(article as SafeStrapiEntity<Article>).isFree && (
                            <div className="flex-none text-xs" title="Free Research">
                                <Link href={linkHref}>
                                    <span className="inline-flex items-center cursor-pointer text-gray-400 hover:text-purple-400">
                                        <GiftIcon className="h-5 w-5 mr-1" />
                                        Free
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className="flex hidden">
                        {/* <button className="cursor-pointer" onClick={() => clickedBookmark(post)}>
                            <BookmarkIcon className="text-purple-800 h-4 w-4" />
                        </button> */}
                        <button
                            className="cursor-pointer ml-1"
                            onClick={() =>
                                track(AnalyticsEvent.clickedPostAction, {
                                    source: router.asPath,
                                    identifier: article.slug,
                                    props: { action: 'Copy' },
                                })
                            }
                        >
                            <ClipboardDocumentIcon className="text-purple-800 h-4 w-4" />
                        </button>
                        <button
                            className="cursor-pointer ml-1"
                            onClick={() =>
                                track(AnalyticsEvent.clickedPostAction, {
                                    source: router.asPath,
                                    identifier: article.slug,
                                    props: { action: 'Share' },
                                })
                            }
                        >
                            <ShareIcon className="text-purple-800 h-4 w-4" />
                        </button>
                        <button
                            className="cursor-pointer ml-1"
                            onClick={() =>
                                track(AnalyticsEvent.clickedPostAction, {
                                    source: router.asPath,
                                    identifier: article.slug,
                                    props: { action: 'Dots-Menu' },
                                })
                            }
                        >
                            <EllipsisHorizontalIcon className="text-purple-800 h-4 w-4" />
                        </button>
                    </div>
                </div>
                <div>
                    <Link href={linkHref} className="block text-base font-semibold leading-5 text-text-dark">
                        {article.title}
                    </Link>
                </div>
                <div className="flex items-center text-xs pt-2">
                    {article.authors?.[0] && (
                        <div className="flex-shrink-0">
                            <a href={`/research/?author=${article.authors[0].slug}`} className="text-gray-600">
                                <span>By {article.authors[0].name}</span>
                            </a>
                            {article.publishedAt && (
                                <span>
                                    <span className="mx-1 text-gray-600">/</span>
                                    <DateFormat className="text-gray-600" dateStringOrTimestamp={article.publishedAt} />
                                </span>
                            )}
                        </div>
                    )}
                </div>
                {showExcerpt && (
                    <div className="flex items-center text-sm pt-2">
                        <p className={classNames(flexRow ? 'line-clamp-3' : '')}>{article.excerpt}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Post;
