import { SafeStrapiEntity } from '../models';

const transformStrapiEntity = <T extends object>(obj: T) => {
    return Object.entries(obj).reduce((carry, [key, value]) => {
        let nextVal = value;
        if (typeof value === 'undefined') {
            nextVal = null;
        } else if (key === 'id') {
            nextVal = `${value}`;
        } else if (key === 'content') {
            const images = Array.from(
                value?.matchAll(/!\[\]\(((.*)(\.)?(jpg|jpeg|gif|png|webp))\)/gi),
                (match: string[]) => [match[0], match[1]],
            );
            // Map images to local media asset paths to STRAPI_URL
            images.forEach(([toReplace, path]) => {
                if (path?.startsWith('/')) {
                    nextVal = nextVal.replace(toReplace, `![](${process.env.STRAPI_URL}${path})`);
                }
            });
        } else if (Array.isArray(value)) {
            nextVal = value?.map(transformStrapiEntity);
        } else if (value instanceof Date) {
            nextVal = value.toISOString();
        } else if (typeof value === 'object' && value !== null) {
            // Map images to local media asset paths to STRAPI_URL
            if (
                ['image', 'featuredImage', 'thumbnail', 'small', 'medium'].includes(key) &&
                typeof value.url === 'string' &&
                value.url.startsWith('/')
            ) {
                nextVal = transformStrapiEntity({
                    ...value,
                    url: `${process.env.STRAPI_URL}${value.url}`,
                });
            } else {
                nextVal = transformStrapiEntity(value);
            }
        }
        return {
            ...carry,
            [key]: nextVal,
        };
    }, {} as SafeStrapiEntity<T>);
};

export { transformStrapiEntity };
