import { StrapiEntity, StrapiParams } from '@rixw/strapi-client';

import { ApiEntities } from './models/entities';
import { Params, SafeStrapiCollection, SafeStrapiEntity } from './models/meta';
import { client } from './client';
import { transformStrapiCollection } from './utils/transform-strapi-collection';
import { transformStrapiEntity } from './utils/transform-strapi-entity';
import { transformStandardEntity } from './utils';

const createEmptyCollection = <T extends StrapiEntity>(): SafeStrapiCollection<T> =>
    Object.assign([], { pagination: {} });

export interface EntityClient<Entity extends StrapiEntity> {
    fetchMany: (params?: Params<Entity>) => Promise<SafeStrapiCollection<Entity>>;
    fetchAll: (params?: Params<Entity>) => Promise<SafeStrapiEntity<Entity>[]>;
    fetchFirst: (params?: Params<Entity>) => Promise<SafeStrapiEntity<Entity> | null>;
}

export interface SingleEntityClient<Entity extends StrapiEntity> {
    fetch: (params?: Params<Entity>) => Promise<SafeStrapiEntity<Entity> | null>;
}

class StrapiEntityClient<T extends Record<ET, EntityClient<any> | SingleEntityClient<any>>, ET extends string> {
    entities: T;

    constructor(entities: T) {
        this.entities = entities;
    }

    addCollection<K extends keyof ApiEntities, Key extends string = K>(
        type: K,
        opts?: { key?: Key; defaultParams?: Params<ApiEntities[K]> },
    ) {
        const { key = type, defaultParams = { populate: '*' } } = opts ?? {};
        const methods = {
            fetchMany: async (params: Params<ApiEntities[K]> = {}): Promise<SafeStrapiCollection<ApiEntities[K]>> => {
                try {
                    const rawItems = await client.fetchMany<ApiEntities[K]>(type, {
                        ...defaultParams,
                        ...params,
                    } as StrapiParams);
                    return transformStrapiCollection<ApiEntities[K]>(rawItems ?? []);
                } catch (e) {
                    console.log(`Error loading ${type}`, e);
                }
                return createEmptyCollection();
            },
            fetchAll: async (params: Params<ApiEntities[K]> = {}): Promise<Array<SafeStrapiEntity<ApiEntities[K]>>> => {
                try {
                    const rawItems = await client.fetchAll<ApiEntities[K]>(type, {
                        ...defaultParams,
                        ...params,
                    } as StrapiParams);
                    return rawItems?.map(transformStrapiEntity);
                } catch (e) {
                    console.log(`Error loading ${type}`, e);
                }
                return [];
            },
            fetchFirst: async (
                params: Params<ApiEntities[K]> = {},
            ): Promise<SafeStrapiEntity<ApiEntities[K]> | null> => {
                try {
                    const rawItem = await client.fetchFirst<ApiEntities[K]>(type, {
                        ...defaultParams,
                        ...params,
                    } as StrapiParams);
                    return rawItem ? transformStrapiEntity<ApiEntities[K]>(rawItem) : rawItem;
                } catch (e) {
                    console.log(`Error loading ${type}`, e);
                }
                return null;
            },
        };

        const newEntity = { [key]: methods } as Record<Key, EntityClient<ApiEntities[K]>>;

        return new StrapiEntityClient({
            ...this.entities,
            ...newEntity,
        });
    }

    addSingle<K extends keyof ApiEntities, Key extends string = K>(
        type: K,
        opts?: {
            key?: Key;
            defaultParams?: Params<ApiEntities[K]>;
            transform?: (entity: any) => any;
        },
    ) {
        const { key = type, defaultParams = { populate: '*' }, transform } = opts ?? {};
        const methods = {
            fetch: async (params: Params<ApiEntities[K]> = {}): Promise<SafeStrapiEntity<ApiEntities[K]> | null> => {
                try {
                    const rawItem = await client.fetchSingle<ApiEntities[K]>(type, {
                        ...defaultParams,
                        ...params,
                    } as StrapiParams);
                    return rawItem
                        ? transformStrapiEntity<ApiEntities[K]>(transform ? transform(rawItem) : rawItem)
                        : rawItem;
                } catch (e) {
                    console.log(`Error loading ${type}`, e);
                }
                return null;
            },
        };

        const newEntity = { [key]: methods } as Record<Key, SingleEntityClient<ApiEntities[K]>>;
        return new StrapiEntityClient({
            ...this.entities,
            ...newEntity,
        });
    }

    build() {
        return this.entities as { [K in keyof T]: T[K] };
    }
}

const StrapiEntities = new StrapiEntityClient({})
    .addCollection('article', {
        defaultParams: { populate: '*', sort: 'publishedAt:desc' },
    })
    .addCollection('flashnote', {
        defaultParams: { populate: '*', sort: 'publishedAt:desc' },
    })
    .addCollection('category', { defaultParams: { sort: 'name:asc' } })
    .addCollection('tag', { defaultParams: { sort: 'name:asc' } })
    .addCollection('author', {
        defaultParams: { populate: '*', sort: 'name:asc' },
    })
    .addCollection('asset-tab', {
        key: 'assetTab',
        defaultParams: { sort: 'order:asc' },
    })
    .addCollection('page')
    .addCollection('dashboard', {
        defaultParams: { populate: { source: { populate: '*' }, tag: true } },
    })
    .addSingle('data-page', {
        key: 'dataPage',
        defaultParams: {
            populate: {
                tabs: {
                    populate: {
                        dashboards: {
                            populate: {
                                tag: true,
                                source: { populate: { parameters: true } },
                            },
                        },
                        image: true,
                    },
                },
                spotlight: {
                    populate: {
                        dashboards: {
                            populate: {
                                tag: true,
                                source: { populate: { parameters: true } },
                            },
                        },
                        image: true,
                    },
                },
            },
        },
        transform: entity => {
            // For whatever reason
            entity.tabs = entity.tabs.map(transformStandardEntity);
            entity.spotlight = entity.spotlight.map(transformStandardEntity);
            return entity;
        },
    })
    .build();

export { StrapiEntities };
