import * as React from 'react';

import { SafeStrapiCollection, Flashnote, Article } from '@blockworks/platform/src/api/strapi/models';

import Post, { PostImageSize } from '@/components/section/Post/Post';

interface IPostList {
    posts: SafeStrapiCollection<Article | Flashnote>;
    imageSize?: PostImageSize;
    flexRow?: boolean;
    showExcerpt?: boolean;
}

const PostList = (props: IPostList) => {
    const { posts, flexRow, imageSize, showExcerpt } = props;

    return (
        <>
            {posts.map((post: any) => (
                <Post
                    key={`post-${post.slug}`}
                    article={post}
                    imageSize={imageSize || PostImageSize.small}
                    flexRow={flexRow}
                    showExcerpt={showExcerpt}
                />
            ))}
        </>
    );
};

export default PostList;
