import React from 'react';

import { classNames } from '../../../utils/functions/class-names';

type SkeletonProps = {
    width?: number | string;
    height?: number;
    rounded?: string;
    className?: string;
};
const HorizontalSkeletonLine = ({ height = 5, width = 'full', rounded = 'rounded-md', className }: SkeletonProps) => {
    return <span className={classNames(`skeleton-line h-${height} w-${width} inline-block ${rounded}`, className)} />;
};

export default HorizontalSkeletonLine;
