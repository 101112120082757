import React from 'react';

import HorizontalSkeletonLine from '@core/components/display/skeleton/HorizontalSkeletonLine';

type PostSkeletonProps = {
    multiple?: boolean;
    imageSize: 'big' | 'small';
};

const PostSkeleton = (props: PostSkeletonProps) => {
    const { multiple, imageSize } = props;
    return (
        <>
            {multiple &&
                new Array(6).fill('post').map((value, index) => (
                    <div key={`skeleton-${value}-${index}`}>
                        <div className="flex-shrink-0 relative mb-2">
                            <HorizontalSkeletonLine
                                className={imageSize === 'big' ? 'h-44' : 'lg:h-36 xl:h-48 2xl:h-64'}
                                rounded="rounded-xl"
                            />
                        </div>

                        <div className="flex-1 p-0 flex flex-col mt-3 md:mt-2">
                            <div className="flex w-48 space-x-4 mb-2">
                                <HorizontalSkeletonLine rounded="rounded-xl" />
                                <HorizontalSkeletonLine rounded="rounded-xl" />
                                <HorizontalSkeletonLine rounded="rounded-xl" />
                            </div>
                            <div className="my-1">
                                <HorizontalSkeletonLine />
                            </div>
                            <div className="">
                                <HorizontalSkeletonLine width={'1/3'} />
                            </div>
                        </div>
                    </div>
                ))}

            <div>
                <div className="flex-shrink-0 relative ">
                    <HorizontalSkeletonLine
                        className={imageSize === 'big' ? 'lg:h-56 xl:h-72 2xl:h-[32rem]' : 'lg:h-64 2xl:h-80'}
                        rounded="rounded-xl"
                    />
                </div>

                <div className="flex-1 p-0 flex flex-col mt-2">
                    <div className="flex w-48 space-x-4 mb-2">
                        <HorizontalSkeletonLine rounded="rounded-xl" />
                        <HorizontalSkeletonLine rounded="rounded-xl" />
                        <HorizontalSkeletonLine rounded="rounded-xl" />
                    </div>
                    <div className="my-1">
                        <HorizontalSkeletonLine width={'2/3'} />
                    </div>
                    <div className="">
                        <HorizontalSkeletonLine width={'1/3'} />
                    </div>
                    <div className="mb-2">
                        <HorizontalSkeletonLine height={20} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostSkeleton;
