import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const NY_TIMEZONE = 'America/New_York';

type IDate = {
    className?: string;
    dateStringOrTimestamp: any;
    format?: string;
};

export const parseTimeStringToDate = (dateStringOrTimestamp: string): Date => {
    // eslint-disable-next-line no-restricted-globals
    let date = isNaN(Number(dateStringOrTimestamp))
        ? parseISO(dateStringOrTimestamp)
        : new Date(
              parseInt(dateStringOrTimestamp, 10) > 9999999999
                  ? dateStringOrTimestamp
                  : parseInt(dateStringOrTimestamp, 10) * 1000,
          );

    if (date.toString() === 'Invalid Date') {
        date = new Date(dateStringOrTimestamp);
    }

    return date;
};

export default function DateFormat(props: IDate) {
    const { dateStringOrTimestamp, className, format: dateFormat } = props;

    const date = parseTimeStringToDate(dateStringOrTimestamp);

    try {
        return (
            <time suppressHydrationWarning dateTime={date.toISOString()} className={className}>
                {formatInTimeZone(date, NY_TIMEZONE, dateFormat || 'LLLL d, yyyy')}
            </time>
        );
    } catch (error) {
        console.error(error);
        return null;
    }
}
