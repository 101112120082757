import * as React from 'react';

import { Tag, SafeStrapiCollection } from '@blockworks/platform/src/api/strapi/models';

import ArticleTag from '@/components/pages/articles/ArticleTag';

type IArticleTags = {
    tags?: SafeStrapiCollection<Tag> | null;
    className?: string;
};

const ArticleTags = ({ tags, className }: IArticleTags) => {
    return (
        <>
            {tags?.map(tag => (
                <ArticleTag
                    key={`tag-${tag.id}`}
                    slug={tag.slug!}
                    name={tag.name || 'Unknown'}
                    color="purple"
                    className={className}
                />
            ))}
        </>
    );
};

export default ArticleTags;
