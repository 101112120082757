import { StrapiClient } from '@rixw/strapi-client';

const resolveUrl = () => {
    if (typeof window === 'undefined') {
        if (process.env.STRAPI_PROXY_URL) return `${process.env.STRAPI_PROXY_URL}/api/strapi`;
        return process.env.STRAPI_URL;
    }
    return `${process.env.SITE_URL}/api/strapi`;
};

const client = new StrapiClient({
    url: resolveUrl(),
    contentTypes: [
        'article',
        'flashnote',
        'category',
        'tag',
        'author',
        'asset-tab',
        'page',
        'dashboard',
        {
            id: `api::data-page.data-page`,
            singularName: 'data-page',
            // Plural path does not work anymore for some reason so we are defining it manually
            path: `/api/data-page`,
        },
    ],
    jwt: process.env.STRAPI_TOKEN,
});

export { client };
