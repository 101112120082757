export const isRelationship = <T>(attribute: unknown): attribute is RelatedEntity<T> => {
    return typeof attribute === 'object' && attribute !== null && Object.hasOwn(attribute, 'data');
};

export const transformRawEntity = <T>({ id, attributes }: RawEntity<T>) => {
    const safeEntity = { id } as ExtractEntity<T>;
    for (const attr in attributes) {
        if (typeof attr === 'string') {
            const relationship = attributes[attr];
            if (isRelationship(relationship)) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                safeEntity[attr] = transformRelatedEntity(relationship) as any;
            } else {
                safeEntity[attr] = attributes[attr] as any;
            }
        }
    }
    return safeEntity as ExtractEntity<T> & { id: number };
};

export const transformStandardEntity = <T extends { id: number }>(entity: T) => {
    const safeEntity = {} as ExtractEntity<T>;
    for (const attr in entity) {
        if (typeof attr === 'string') {
            const relationship = entity[attr];
            if (isRelationship(relationship)) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                safeEntity[attr] = transformRelatedEntity(relationship) as any;
            } else {
                safeEntity[attr] = entity[attr] as any;
            }
        }
    }
    return safeEntity as ExtractEntity<T> & { id: number };
};

export const transformRelatedEntity = <T>({ data }: RelatedEntity<T>) => {
    if (Array.isArray(data)) {
        return data.map(transformRawEntity);
    }

    if (typeof data === 'object' && data !== null) {
        return transformRawEntity(data);
    }

    return data;
};

type FlattenRelationship<T> = T extends RelatedEntity<infer Y>
    ? ExtractEntity<Y>
    : T extends MultiRelatedEntity<infer Z>
    ? ExtractEntity<Z>[]
    : T;

type ExtractEntity<T> = {
    [K in keyof T]: FlattenRelationship<T[K]>;
};

interface RawEntity<T> {
    id: number;
    attributes: T;
}

interface RelatedEntity<T> {
    data: RawEntity<T>;
}

interface MultiRelatedEntity<T> {
    data: Array<RawEntity<T>>;
}
