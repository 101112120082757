import * as React from 'react';

import Label from '@core/components/display/labels/Label';

enum LabelColor {
    purple,
}

type LabelColorStrings = keyof typeof LabelColor;

export type IArticleTag = {
    slug: string;
    name: string;
    color?: LabelColorStrings;
    className?: string;
};

const ArticleTag = (props: IArticleTag) => {
    return (
        <Label key={`label-${props.slug}}`} href={`/research`} slug={props.slug} className={props.className} smaller>
            {props.name}
        </Label>
    );
};

export default ArticleTag;
