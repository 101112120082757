import { StrapiEntity, StrapiPaginatedArray } from '@rixw/strapi-client';

import { SafeStrapiCollection } from '../models';
import { transformStrapiEntity } from './transform-strapi-entity';

const transformStrapiCollection = <T extends StrapiEntity>(items: StrapiPaginatedArray<T>) => {
    const { pagination } = items;
    const transformedItems = items.map(transformStrapiEntity) as SafeStrapiCollection<T>;
    transformedItems.pagination = pagination;
    return transformedItems;
};

export { transformStrapiCollection };
